import { forFriends } from './for-friends'
import { forYou } from './for-you'
import { forCouples } from './for-couples'


// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answes | multiple

type Choice = string
type CorrectAnswers = string[]

export type Question = {
  question: string
  choices: Choice[]
  type: 'MCQs' | 'MAQs' | 'boolean'
  correctAnswers: CorrectAnswers
  score: number
  code?: string
  image?: string
}

export type Topic = {
  topic: string
  level: string
  totalQuestions: number
  totalTime: number
  questions: Question[]
}

export const quiz: Record<string, Topic> = {
  friends: forFriends,
  couples: forCouples,
  you: forYou
}
