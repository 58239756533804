// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const forFriends: Topic = {
  topic: 'friends',
  level: 'Beginner',
  totalQuestions: 14,
  totalTime: 120,
  questions: [],
}
