import { FC } from 'react';
import styled from 'styled-components';

interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
}

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SliderInput = styled.input`
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
  -webkit-appearance: none;
  appearance: none;
  background: ${({ theme }) => theme.colors.background || '#ddd'};
  height: 5px;
  border-radius: 5px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.themeColor || '#333'};
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
`;

const SliderLabel = styled.label`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const SliderValue = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.themeColor || '#333'};
`;

const Slider: FC<SliderProps> = ({
  value,
  onChange,
  min = 1,
  max = 5,
  step = 1,
}) => {
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
  };

  return (
    <SliderContainer>
      <SliderValue>{value}</SliderValue>
      <SliderInput
        id="slider"
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleSliderChange}
      />
    </SliderContainer>
  );
};

export default Slider;
