import styled from 'styled-components'

import { StartIcon } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from '../../styles/Global'
import { ScreenTypes } from '../../types'
import { convertSeconds } from '../../utils/helpers'

import Button from '../ui/Button'
import Slider from '../ui/Slider'

//TEST
import { Ice } from '../../config/icons';


const DetailTextContainer = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 70%;
`

const DetailText = styled.p`
  font-size: 30px;
  font-weight: 500;
  margin-top: 15px;
  line-height: 1.3;
  text-align: center;
`

const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  padding: 20px;
`


const QuizDetailsScreen = () => {
  const { setCurrentScreen, quizDetails, quizDepth, timer, setTimer, setQuizDepth} = useQuiz()

  const { selectedQuizTopic, totalTime } = quizDetails

  const goToQuestionScreen = () => {
    setCurrentScreen(ScreenTypes.QuestionScreen)
  }

  const setMinutesTimer = (value: number) => {
    setTimer(60 * value)
  }

  return (
    // <PageCenter light justifyCenter>
      <CenterCardContainer>
        <Heading>
          <HighlightedText>
            <Ice style={{ color: 'blue', marginRight: '8px', fontSize: '2px' }} />
             AI
          </HighlightedText>
          CEBREAKERS 
          <Ice style={{ color: 'blue', marginLeft: '8px', fontSize: '2px' }} />
        </Heading>
          <DetailTextContainer>
          {selectedQuizTopic === "friends" && <DetailText>
            Show your friend who you really are
          </DetailText>}
          {selectedQuizTopic === "couples" && <DetailText>
            Hey lovers, are you really partners in crime?
          </DetailText>}
          {selectedQuizTopic === "you" && <DetailText>
            Take some time to get to know you
          </DetailText>}

          <DetailText>
            Set time in minutes
          </DetailText>
          <Slider value={timer/60} onChange={setMinutesTimer} min={1} max={15} step={1} />


          {/* Add quizDepth slider */}
          <DetailText>
            Select the depth of the questions
          </DetailText>
          <DetailText>
            from 1 (why u so shy ) to 5 (kinda oversharing)
          </DetailText>

          < Slider value={quizDepth} onChange={setQuizDepth} min={1} max={5} step={1} />

        </DetailTextContainer>
        <Button
          text="Start"
          icon={<StartIcon />}
          iconPosition="left"
          onClick={goToQuestionScreen}
          bold
        />
      </CenterCardContainer>
    // </PageCenter>
  )
}

export default QuizDetailsScreen
