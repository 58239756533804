import React, { useEffect, useState } from 'react';
import { FC } from 'react'
import styled from 'styled-components'
import Spinner from './ui/Spinner';

const StyledQuestion = styled.h2`
  font-size: clamp(18px, 4vw, 28px);
  font-weight: 500;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.primaryText};
  line-height: 1.3;
`

interface QuestionGeneratorProps {
    topic: string,
    extraInfo?: string,
    questionId: number
    questionDepth?: number
  }

const QuestionGenerator: FC<QuestionGeneratorProps> = ({ topic, extraInfo, questionId, questionDepth}) => {
    const [question, setQuestion] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchQuestion()
    }, [questionId])
 
    const fetchQuestion = async () => {

        let prompt = "Generate a question"

        if (topic === "friends"){
            prompt = "Ask a question to a friend to increase vulnerabiliity. For example: What’s something you’ve never told anyone but feel comfortable sharing now?, What’s one thing you wish more people knew about you?, If you could relive any moment in our friendship, what would it be?, What’s a secret dream or goal you’ve never shared with anyone?, What’s something you’ve always admired about me but never said out loud?, What’s a fear you’ve never told anyone about before?, What’s one thing you’ve learned from our friendship that you value the most?, If you could change one thing about our friendship, what would it be?, What’s the most meaningful compliment you’ve ever received, and who gave it to you?, What’s a quality you’ve always wanted to develop in yourself but never told anyone?, What’s a memory you cherish but rarely talk about?"
        } else if (topic === "couples"){
            prompt = "Ask a question to your partner to increase vulnerability. For example: What do you think is our greatest strength as a couple?, What’s something you’ve never told me but feel ready to share now?, When do you feel most loved by me?, What’s a fear or worry you haven’t shared with me yet?, How can I support you better during difficult times?, What is one thing you admire about me that you haven’t expressed before?, How do you envision our future together?,What is your happiest memory of us, and why is it so special?, Is there anything in our relationship that you wish we could change or improve?, How can we work better as a team when facing challenges?,What are three things you love most about our relationship?,What is something I do that makes you feel truly understood?, How do you feel we have grown together as a couple?, What is a secret dream or goal you have for us as a couple?,What is something I can do to make you feel more appreciated or valued?, What is a moment in our relationship that made you feel particularly connected to me?, How do you handle conflict and how can I help make resolving issues easier for you?, What’s a quality in me that you think makes us stronger as a couple?, What is a vulnerable moment you have experienced in our relationship that helped you grow?"
        } else if (topic === "you") {
            prompt = "Ask a question to yourself for journaling. For example: What’s something you need to forgive yourself for?, What’s a limiting belief you want to let go of?, How can you show more compassion to yourself during difficult times?, What’s something you’ve been avoiding that you need to confront?, What’s a habit or mindset you’d like to change, and why?, When was the last time you truly felt at peace? What contributed to that feeling?, What are you most proud of about yourself, and why?, What’s a part of your past you need to accept in order to move forward?, How can you prioritize your own well-being more in your daily life?, What’s a personal boundary you need to set, and why is it important?, How have you grown from a difficult experience in the past year?, What’s one small change you can make today that would bring more joy into your life?, How can you better balance your personal needs with the expectations of others?, What brings you a sense of purpose, and how can you align your actions with it?"
        } 

        if (extraInfo){
            prompt += extraInfo
        }

        // add a level of depth to the question
        if (questionDepth){
            prompt += `Consider that the level of depth is between 1,
            which means superficial, and 5, which means very deep, and in
            this case the depth is ${questionDepth}.
            `
        }



        setLoading(true);
        setQuestion(null); // Clear previous question

        const nodeEnv = process.env.NODE_ENV

        console.log('process.env', process.env);

        let apiUrl = 'http://localhost:3000/api/generate-questions'

        if (nodeEnv === 'production'){
            apiUrl = 'https://aicebreakers.vercel.app/api/generate-questions'
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt }), // Send the fixed prompt to the server
            });

            if (!response.ok) {
                throw new Error('Failed to fetch question');
            }

            const data = await response.json();
            setQuestion(data.question || 'No question generated');
        } catch (error) {
            console.error('Error fetching question:', error);
            setQuestion('Sorry, something went wrong!');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="question-generator">
            {loading ? <Spinner /> : <StyledQuestion>{question}</StyledQuestion>}
        </div>
    );
};

export default QuestionGenerator;
