import { createContext, useContext } from 'react'
import { QuizContextTypes, ScreenTypes } from '../types'

export const initialState: QuizContextTypes = {
  currentScreen: ScreenTypes.SplashScreen,
  setCurrentScreen: () => {},
  quizTopic: 'friends',
  quizDepth: 4,
  setQuizDepth: () => {},
  selectQuizTopic: () => {},
  timer: 15,
  setTimer: () => {},
  endTime: 0,
  setEndTime: () => {},
  quizDetails: {
    totalQuestions: 0,
    totalTime: 0,
    selectedQuizTopic: 'friends'
  },
}


export const QuizContext = createContext<QuizContextTypes>(initialState)

export function useQuiz() {
  console.log("INITIAL STATE QUIZ CONTEXT: ", QuizContext)
  return useContext(QuizContext)
}
