import { PageCenter } from '../../styles/Global'

const SplashScreen = () => {

  return (
    //  <PageCenter justifyCenter>
    //    {/* <LogoAnimation logoSize={logoSize}>
    //     <AppLogo />
    //    </LogoAnimation> */}
    // </PageCenter>
    <div></div>
  )
}

export default SplashScreen
