import { ReactNode } from 'react'
import friendsImage from '../assets/images/friends.jpg';
import couplesImage from '../assets/images/couples.jpg';
import youImage from '../assets/images/you.jpg';

const imageWidth = '200px'
const imageHeight = '100px'
const imageBorderRadius = '10px'


const FriendsImageComponent: React.FC = () => {
  return (
    <div>
      <img src={friendsImage}
        alt=''
        style={{ width: imageWidth, height: imageHeight, objectFit: 'cover', borderRadius: imageBorderRadius}}
      />
    </div>
  );
}

const CouplesImageComponent: React.FC = () => {
  return (
    <div>
      <img src={couplesImage}
        alt=''
        style={{ width: imageWidth, height: imageHeight, objectFit: 'cover', borderRadius: imageBorderRadius}}
      />
    </div>
  );
}

const YouImageComponent: React.FC = () => {
  return (
    <div>
      <img src={youImage}
        alt=''
        style={{ width: imageWidth, height: imageHeight, objectFit: 'cover', borderRadius: imageBorderRadius}}
      />
    </div>
  );
}
type QuizTopic = {
  title: string
  icon?: ReactNode
  image?: ReactNode
  disabled?: boolean
}

export const quizTopics: QuizTopic[] = [
  {
    title: 'friends',
    image: <FriendsImageComponent />
  },
  {
    title: 'couples',
    image: <CouplesImageComponent />
  },
  {
    title: 'you',
    image: <YouImageComponent />
  },

]
