import { FC } from 'react'
import styled from 'styled-components'

import { device } from '../../../styles/BreakPoints'
import QuestionGenerator from '../../LLMAgent'

const QuestionContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  max-width: 88%;
  @media ${device.sm} {
    max-width: 100%;
  }
`
interface QuestionTypes {
  topic: string
  questionId: number,
  questionDepth?: number
}

const Question: FC<QuestionTypes> = ({
  topic,
  questionId,
  questionDepth
}) => {
  return (
    <QuestionContainer>
      <QuestionGenerator topic={topic} questionId={questionId} questionDepth={questionDepth} />
    </QuestionContainer>
  )
}

export default Question
