import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const SpinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerWrapper = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid ${({ theme }) => theme.colors.background || '#f3f3f3'};
  border-top: 4px solid ${({ theme }) => theme.colors.themeColor || '#3498db'};
  border-radius: 50%;
  animation: ${SpinnerAnimation} 1s linear infinite;
`;

const Spinner: FC = () => <SpinnerWrapper />;

export default Spinner;
