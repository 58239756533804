import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Next, Previous, Finish,  TimerIcon } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import { useTimer } from '../../hooks'
import { device } from '../../styles/BreakPoints'
import { CenterCardContainer, PageCenter } from '../../styles/Global'

import Button from '../ui/Button'
import ModalWrapper from '../ui/ModalWrapper'
import Question from './Question'
import QuizHeader from './QuizHeader'
import { refreshPage } from '../../utils/helpers'

const QuizContainer = styled.div<{ selectedAnswer: boolean }>`
  width: 900px;
  min-height: 500px;
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 4px;
  padding: 30px 60px 80px 60px;
  margin-bottom: 70px;
  position: relative;
  @media ${device.md} {
    width: 100%;
    padding: 15px 15px 80px 15px;
  }
  button {
    span {
      svg {
        path {
          fill: ${({ selectedAnswer, theme }) =>
    selectedAnswer ? `${theme.colors.buttonText}` : `${theme.colors.darkGray}`};
        }
      }
    }
  }
`

const ButtonWrapper = styled.div`
  position: absolute;
  right: 60px;
  bottom: 30px;
  display: flex;
  gap: 20px;
  @media ${device.sm} {
    justify-content: flex-end;
    width: 90%;
    right: 15px;
  }
`

const QuestionScreen: FC = () => {
  const [activeQuestion, setActiveQuestion] = useState<number>(0)
  const [showTimerModal, setShowTimerModal] = useState<boolean>(false)
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false)

  const {
    quizDetails,
    timer,
    quizDepth,
    setTimer,
    setEndTime,
  } = useQuiz()


  const onClickNext = () => {
    setActiveQuestion(activeQuestion + 1)
  }

  const onClickPrevious = () => {
    setActiveQuestion(activeQuestion - 1)
  }

  const onClickFinish = () => {
    setShowFinishModal(true)
  }

  const handleModal = () => {
    refreshPage()
  }

  // to prevent scrolling when modal is opened
  useEffect(() => {
    if (showTimerModal || showFinishModal) {
      document.body.style.overflow = 'hidden'
    }
  }, [showTimerModal, showFinishModal])

  // timer hooks, handle conditions related to time
  useTimer(timer, quizDetails, setEndTime, setTimer, setShowTimerModal)

  return (
    // <PageCenter>
      <CenterCardContainer>
      <QuizContainer selectedAnswer={true}>
        <QuizHeader
          activeQuestion={activeQuestion}
          totalQuestions={1000}
          timer={timer}
        />
        <Question
          topic={quizDetails.selectedQuizTopic}
          questionId={activeQuestion}
          questionDepth={quizDepth}
        />
        <ButtonWrapper>
        {/* <Button
            text={'Previous'}
            onClick={onClickPrevious}
            icon={<Previous />}
            iconPosition="right"
          /> */}
          <Button
            text={'Next'}
            onClick={onClickNext}
            icon={<Next />}
            iconPosition="right"
          />
            <Button
            text={'Finish'}
            onClick={onClickFinish}
            icon={<Finish />}
            iconPosition="right"
          />
        </ButtonWrapper>

      </QuizContainer>
      {(showTimerModal) && (
        <ModalWrapper
          title={'Your time is up!'}
          subtitle={`Well done.`}
          onClick={handleModal}
          icon={<TimerIcon />}
          buttonTitle="Restart"
        />
      )}
      {(showFinishModal) && (
        <ModalWrapper
          title={'You have finished the game!'}
          subtitle={`Well done.`}
          onClick={handleModal}
          icon={<></>}
          buttonTitle="Restart"
        />
      )}
      </CenterCardContainer>
     // </PageCenter>

  )
}

export default QuestionScreen
