import React, { useEffect } from 'react';

import { useQuiz } from '../../context/QuizContext';
import { ScreenTypes } from '../../types';

import QuestionScreen from '../QuestionScreen';
import QuizDetailsScreen from '../QuizDetailsScreen';
import QuizTopicsScreen from '../QuizTopicsScreen';
import SplashScreen from '../SplashScreen';

import './Main.css';

function Main() {
  const { currentScreen, setCurrentScreen } = useQuiz();

  useEffect(() => {
    setTimeout(() => {
      setCurrentScreen(ScreenTypes.QuizTopicsScreen);
    }, 10);
  }, [setCurrentScreen]);

  const screenComponents = {
    [ScreenTypes.SplashScreen]: <SplashScreen />,
    [ScreenTypes.QuizTopicsScreen]: <QuizTopicsScreen />,
    [ScreenTypes.QuizDetailsScreen]: <QuizDetailsScreen />,
    [ScreenTypes.QuestionScreen]: <QuestionScreen />,
  };

  const ComponentToRender = screenComponents[currentScreen] || <SplashScreen />;


  return (
    <div className={`main-container`}>
      {/* Background video */}
      <video autoPlay muted loop className="background-video">
        <source src={require('../../assets/videos/BGvideo.mp4')} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Main content */}
      <div className="content">{ComponentToRender}</div>
    </div>
  );
}

export default Main;
